var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aForm',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":12}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Nome da filial ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `name`,
              {
                initialValue: _vm.theCompanyBranch.name,
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `name`,\n              {\n                initialValue: theCompanyBranch.name,\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":"  Nome da filial"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v("Status ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `status`,
              {
                initialValue: _vm.theCompanyBranch.status,
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `status`,\n              {\n                initialValue: theCompanyBranch.status,\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"show-search":"","placeholder":"Status"}},_vm._l((_vm.status),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n              "+_vm._s(item.label)+"\n            ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Telefone/Celular ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `phone`,
              {
                initialValue: _vm.theCompanyBranch.phone,
              },
            ]),expression:"[\n              `phone`,\n              {\n                initialValue: theCompanyBranch.phone,\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Alerta de check-in próximo (contratos) ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `days_to_checkin_alert`,
              {
                initialValue: _vm.theCompanyBranch.days_to_checkin_alert,
              },
            ]),expression:"[\n              `days_to_checkin_alert`,\n              {\n                initialValue: theCompanyBranch.days_to_checkin_alert,\n              },\n            ]"}],staticClass:"travel-input",attrs:{"type":"number","placeholder":"Digite"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" E-mail de comunicação ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `from_email`,
              {
                initialValue: _vm.theCompanyBranch.from_email,
                rules: [
                  {
                    type: 'email',
                    message: 'Escreva um e-mail válido',
                  },
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `from_email`,\n              {\n                initialValue: theCompanyBranch.from_email,\n                rules: [\n                  {\n                    type: 'email',\n                    message: 'Escreva um e-mail válido',\n                  },\n                  {\n                    required: false,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira o e-mail"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{staticClass:"mb-20",attrs:{"span":24}},[_c('a-alert',{staticClass:"f10",attrs:{"type":"info","message":"O e-mail informado será utilizado para realização de comunicações por e-mail com os clientes.","banner":""}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('a-checkbox',{staticClass:"mb-20",staticStyle:{"font-size":"12px","font-weight":"500","position":"relative"},model:{value:(_vm.theCompanyBranch.show_in_contract),callback:function ($$v) {_vm.$set(_vm.theCompanyBranch, "show_in_contract", $$v)},expression:"theCompanyBranch.show_in_contract"}},[_vm._v("\n          Mostrar no contrato\n        ")]),_vm._v(" "),_c('aFormItem',{staticStyle:{"display":"none"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'show_in_contract',
              {
                initialValue: _vm.theCompanyBranch.show_in_contract,
              },
            ]),expression:"[\n              'show_in_contract',\n              {\n                initialValue: theCompanyBranch.show_in_contract,\n              },\n            ]"}],attrs:{"value":_vm.theCompanyBranch.show_in_contract}})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('label',{staticStyle:{"font-size":"10px !important","font-weight":"500","color":"#ccc","transition":"0.3s","line-height":"1","display":"block","width":"100%","margin-bottom":"5px"},attrs:{"for":""}},[_vm._v("\n          Logo PDF")]),_vm._v(" "),(!_vm.theCompanyBranch.logo)?_c('span',[_c('a-upload',{attrs:{"name":"file","multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload.php?folder=public/company-branch/${_vm.theCompanyBranch.id}`,"remove":_vm.removeUploadedFile,"headers":{
              authorization: `Bearer ${_vm.$store.state.token}`,
            }},on:{"change":_vm.uploadedFile},model:{value:(_vm.theCompanyBranch.logo),callback:function ($$v) {_vm.$set(_vm.theCompanyBranch, "logo", $$v)},expression:"theCompanyBranch.logo"}},[_c('a-button',{staticClass:"mt-5",attrs:{"type":"dashed","size":"large","shape":"circle"}},[_c('a-icon',{attrs:{"type":"file"}})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.theCompanyBranch.logo)?_c('span',[_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":_vm.theCompanyBranch.logo}}),_vm._v(" "),_c('a-icon',{staticClass:"red ml-10",attrs:{"type":"close-circle"},on:{"click":function($event){_vm.theCompanyBranch.logo = ''}}})],1):_vm._e()]),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('div',{staticClass:"label"},[_vm._v("Logo email")]),_vm._v(" "),(!_vm.theCompanyBranch.logo_email)?_c('div',[_c('a-upload',{attrs:{"name":"file","multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=company-branch&id=${_vm.theCompanyBranch.id}&sub-folder=logo-email`,"remove":_vm.removeUploadedFile,"headers":{
              authorization: `Bearer ${this.$store.state.token}`,
            }},on:{"change":_vm.uploadedFileS3},model:{value:(_vm.theCompanyBranch.logo_email),callback:function ($$v) {_vm.$set(_vm.theCompanyBranch, "logo_email", $$v)},expression:"theCompanyBranch.logo_email"}},[(_vm.theCompanyBranch.logo_email == '')?_c('a-button',{attrs:{"type":"dashed","shape":"circle","size":"large"}},[_c('a-icon',{attrs:{"type":"paper-clip"}})],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.theCompanyBranch.logo_email)?_c('div',{staticClass:"file-link relative"},[(_vm.theCompanyBranch.logo_email)?_c('a',{attrs:{"href":_vm.theCompanyBranch.logo_email,"target":"_blank"}},[(_vm.checkFileType(_vm.theCompanyBranch.logo_email))?_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":_vm.theCompanyBranch.logo_email,"alt":"logo"}}):_c('a-icon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"paper-clip"}})],1):_vm._e(),_vm._v(" "),_c('a-icon',{staticClass:"close-circle red",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.removefile('logo-email')}}})],1):_vm._e()]),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('aDivider',{staticClass:"mt-15"}),_vm._v(" "),_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":12}},[_c('div',{staticClass:"label"},[_vm._v("Banner extra (E-mail)")]),_vm._v(" "),(!_vm.theCompanyBranch.email_banner_image)?_c('div',[_c('a-upload',{attrs:{"name":"file","multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=company-branch&id=${_vm.theCompanyBranch.id}&sub-folder=banner-email`,"remove":_vm.removeUploadedFile,"headers":{
                  authorization: `Bearer ${this.$store.state.token}`,
                }},on:{"change":_vm.uploadedFileS3},model:{value:(_vm.theCompanyBranch.email_banner_image),callback:function ($$v) {_vm.$set(_vm.theCompanyBranch, "email_banner_image", $$v)},expression:"theCompanyBranch.email_banner_image"}},[(_vm.theCompanyBranch.email_banner_image == '')?_c('a-button',{attrs:{"type":"dashed","shape":"circle","size":"large"}},[_c('a-icon',{attrs:{"type":"paper-clip"}})],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.theCompanyBranch.email_banner_image)?_c('div',{staticClass:"file-link relative"},[(_vm.theCompanyBranch.email_banner_image)?_c('a',{attrs:{"href":_vm.theCompanyBranch.email_banner_image,"target":"_blank"}},[(_vm.checkFileType(_vm.theCompanyBranch.email_banner_image))?_c('img',{staticStyle:{"max-width":"230px"},attrs:{"src":_vm.theCompanyBranch.email_banner_image,"alt":"banner"}}):_c('a-icon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"paper-clip"}})],1):_vm._e(),_vm._v(" "),_c('a-icon',{staticClass:"close-circle red",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.removefile('banner-email')}}})],1):_vm._e()]),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('aFormItem',{staticClass:"travel-input-outer mt-10"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v("\n                Link Banner extra (E-mail)\n              ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `email_banner_url`,
                  {
                    initialValue: _vm.theCompanyBranch.email_banner_url,
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `email_banner_url`,\n                  {\n                    initialValue: theCompanyBranch.email_banner_url,\n                    rules: [\n                      {\n                        required: false,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira o link de destino (opcional)"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1),_vm._v(" "),_c('aDivider',{staticClass:"mt-5"})],1),_vm._v(" "),_c('aCol',{staticClass:"a-center",attrs:{"span":24}},[_c('a-button',{attrs:{"size":"large","type":"primary","loading":_vm.loadingUpdateCompany,"html-type":"submit"}},[_vm._v("\n          ATUALIZAR FILIAL\n        ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }