<template>
  <div>
    <aForm :form="form" @submit="handleSubmit">
      <aRow :gutter="20">
        <aCol :span="12">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Nome da filial </label>
            <a-input
              class="travel-input"
              placeholder="  Nome da filial"
              v-decorator="[
                `name`,
                {
                  initialValue: theCompanyBranch.name,
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol :span="12">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'">Status </label>

            <a-select
              class="travel-input"
              show-search
              placeholder="Status"
              v-decorator="[
                `status`,
                {
                  initialValue: theCompanyBranch.status,
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="(item, index) of status"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </aFormItem>
        </aCol>

        <aCol :span="12">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Telefone/Celular </label>

            <a-input
              class="travel-input"
              placeholder="Escreva"
              v-decorator="[
                `phone`,
                {
                  initialValue: theCompanyBranch.phone,
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol :span="12">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Alerta de check-in próximo (contratos) </label>
            <a-input
              class="travel-input"
              type="number"
              placeholder="Digite"
              v-decorator="[
                `days_to_checkin_alert`,
                {
                  initialValue: theCompanyBranch.days_to_checkin_alert,
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol :span="12">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> E-mail de comunicação </label>

            <a-input
              class="travel-input"
              placeholder="Insira o e-mail"
              v-decorator="[
                `from_email`,
                {
                  initialValue: theCompanyBranch.from_email,
                  rules: [
                    {
                      type: 'email',
                      message: 'Escreva um e-mail válido',
                    },
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol class="mb-20" :span="24">
          <a-alert
            class="f10"
            type="info"
            message="O e-mail informado será utilizado para realização de comunicações por e-mail com os clientes."
            banner
          />
        </aCol>

        <aCol :span="24">
          <a-checkbox
            class="mb-20"
            v-model="theCompanyBranch.show_in_contract"
            style="font-size: 12px; font-weight: 500; position: relative"
          >
            Mostrar no contrato
          </a-checkbox>

          <aFormItem style="display: none">
            <a-input
              v-decorator="[
                'show_in_contract',
                {
                  initialValue: theCompanyBranch.show_in_contract,
                },
              ]"
              :value="theCompanyBranch.show_in_contract"
            />
          </aFormItem>
        </aCol>

        <aCol :span="12">
          <label
            for
            style="
              font-size: 10px !important;
              font-weight: 500;
              color: #ccc;
              transition: 0.3s;
              line-height: 1;
              display: block;
              width: 100%;
              margin-bottom: 5px;
            "
          >
            Logo PDF</label
          >
          <span v-if="!theCompanyBranch.logo">
            <a-upload
              name="file"
              :multiple="false"
              :action="`https://api.viajar.tur.br/v1/media/upload.php?folder=public/company-branch/${theCompanyBranch.id}`"
              :remove="removeUploadedFile"
              :headers="{
                authorization: `Bearer ${$store.state.token}`,
              }"
              @change="uploadedFile"
              v-model="theCompanyBranch.logo"
            >
              <a-button type="dashed" class="mt-5" size="large" shape="circle">
                <a-icon type="file" />
              </a-button>
            </a-upload>
          </span>
          <span v-if="theCompanyBranch.logo">
            <img :src="theCompanyBranch.logo" style="max-width: 100px" />
            <a-icon
              @click="theCompanyBranch.logo = ''"
              class="red ml-10"
              type="close-circle"
            />
          </span>
        </aCol>

        <aCol :span="12">
          <div class="label">Logo email</div>

          <div v-if="!theCompanyBranch.logo_email">
            <a-upload
              name="file"
              :multiple="false"
              :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=company-branch&id=${theCompanyBranch.id}&sub-folder=logo-email`"
              :remove="removeUploadedFile"
              :headers="{
                authorization: `Bearer ${this.$store.state.token}`,
              }"
              @change="uploadedFileS3"
              v-model="theCompanyBranch.logo_email"
            >
              <a-button
                v-if="theCompanyBranch.logo_email == ''"
                type="dashed"
                shape="circle"
                size="large"
              >
                <a-icon type="paper-clip" />
              </a-button>
            </a-upload>
          </div>

          <div v-if="theCompanyBranch.logo_email" class="file-link relative">
            <a
              v-if="theCompanyBranch.logo_email"
              :href="theCompanyBranch.logo_email"
              target="_blank"
            >
              <img
                v-if="checkFileType(theCompanyBranch.logo_email)"
                :src="theCompanyBranch.logo_email"
                style="max-width: 100px"
                alt="logo"
              />
              <a-icon
                v-else
                class="cblue"
                type="paper-clip"
                style="font-size: 38px"
              />
            </a>

            <a-icon
              class="close-circle red"
              type="close-circle"
              @click="removefile('logo-email')"
            />
          </div>
        </aCol>

        <aCol :span="24">
          <aDivider class="mt-15" />
          <aRow :gutter="20">
            <aCol :span="12">
              <div class="label">Banner extra (E-mail)</div>

              <div v-if="!theCompanyBranch.email_banner_image">
                <a-upload
                  name="file"
                  :multiple="false"
                  :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=company-branch&id=${theCompanyBranch.id}&sub-folder=banner-email`"
                  :remove="removeUploadedFile"
                  :headers="{
                    authorization: `Bearer ${this.$store.state.token}`,
                  }"
                  @change="uploadedFileS3"
                  v-model="theCompanyBranch.email_banner_image"
                >
                  <a-button
                    v-if="theCompanyBranch.email_banner_image == ''"
                    type="dashed"
                    shape="circle"
                    size="large"
                  >
                    <a-icon type="paper-clip" />
                  </a-button>
                </a-upload>
              </div>

              <div
                v-if="theCompanyBranch.email_banner_image"
                class="file-link relative"
              >
                <a
                  v-if="theCompanyBranch.email_banner_image"
                  :href="theCompanyBranch.email_banner_image"
                  target="_blank"
                >
                  <img
                    v-if="checkFileType(theCompanyBranch.email_banner_image)"
                    :src="theCompanyBranch.email_banner_image"
                    style="max-width: 230px"
                    alt="banner"
                  />
                  <a-icon
                    v-else
                    class="cblue"
                    type="paper-clip"
                    style="font-size: 38px"
                  />
                </a>

                <a-icon
                  class="close-circle red"
                  type="close-circle"
                  @click="removefile('banner-email')"
                />
              </div>
            </aCol>
            <aCol :span="12">
              <aFormItem class="travel-input-outer mt-10">
                <label for :class="'filled'">
                  Link Banner extra (E-mail)
                </label>
                <a-input
                  class="travel-input"
                  placeholder="Insira o link de destino (opcional)"
                  v-decorator="[
                    `email_banner_url`,
                    {
                      initialValue: theCompanyBranch.email_banner_url,
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </aFormItem>
            </aCol>
          </aRow>
          <aDivider class="mt-5" />
        </aCol>

        <aCol class="a-center" :span="24">
          <a-button
            size="large"
            type="primary"
            :loading="loadingUpdateCompany"
            html-type="submit"
          >
            ATUALIZAR FILIAL
          </a-button>
        </aCol>
      </aRow>
    </aForm>
  </div>
</template>

<script>
export default {
  name: "EditCompanyBranchData",
  props: {
    theCompanyBranch: Object,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loadingUpdateCompany: false,
      status: [
        {
          label: "Ativo",
          value: "Ativo",
        },
        {
          label: "Desativado",
          value: "Desativado",
        },
      ],
    };
  },
  mounted() {
    this.theCompanyBranch.show_in_contract =
      this.theCompanyBranch.show_in_contract == 1 ? true : false;
  },
  methods: {
    uploadedFile(response) {
      if (!response.file.response.status) {
        this.$message.error(response.file.response.message);
      } else {
        this.$message.success(response.file.response.message);
      }

      if (response.file.response.url != undefined) {
        this.theCompanyBranch.logo = response.file.response.url;
      }
    },
    checkFileType(fileUrl) {
      let flag = false;
      let filePieces = fileUrl.split(".");
      if (
        filePieces[filePieces.length - 1] == "jpg" ||
        filePieces[filePieces.length - 1] == "png"
      ) {
        flag = true;
      }

      return flag;
    },
    removeUploadedFile(file) {
      file;
    },
    uploadedFileS3(response) {
      this.$message.success(response.file.response.message);
      if (response.file.response != undefined) {
        if (response.file.response.url != undefined) {
          let urlPieces = response.file.response.url.split("/");
          if (urlPieces[5] == "logo-email")
            this.theCompanyBranch.logo_email = response.file.response.url;

          if (urlPieces[5] == "banner-email")
            this.theCompanyBranch.email_banner_image =
              response.file.response.url;
        }
      }
    },
    removefile(fileName) {
      if (fileName == "logo-email") this.theCompanyBranch.logo_email = "";
      if (fileName == "banner-email")
        this.theCompanyBranch.email_banner_image = "";
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        values.logo = this.theCompanyBranch.logo;
        values.logo_email = this.theCompanyBranch.logo_email;
        values.email_banner_image = this.theCompanyBranch.email_banner_image;
        values.id = this.theCompanyBranch.id;
        values.company_id = this.theCompanyBranch.company_id;

        console.log(err, values);
        if (!err) {
          this.loadingUpdateCompany = true;

          this.$http
            .post("/company-branch/update", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingUpdateCompany = false;
              this.$emit("listCompanyBranches", true);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingUpdateCompany = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.label
  font-size: 10px !important
  font-weight: 500
  color: rgb(204, 204, 204)
  transition: all 0.3s ease 0s
  line-height: 1
  display: block
  width: 100%
  margin-bottom: 5px
</style>
